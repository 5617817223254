import React, { useEffect, useState } from 'react';
import { Grid, OutlinedInput, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { useSnackbar } from 'notistack';

import { ListContainer } from 'components/Items/Container';
import { SearchForm } from 'components/Items/Form';
import { SmallSubmitButton, TooltipButton } from 'components/Items/Button';
import { DataTableSort } from 'components/Items/Table';
import { YyyyMmDd, YyyyMmDdHhMmSs } from 'components/Function/ChangeText';

const ClientListView = (props) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const keys = [
        { key: 'No', label: 'No.'},
        { key: 'CounselorName', label: '상담자'},
        { key: 'ClientName', label: '내담자'},
        { key: 'NickName', label: '내담자ID'},
        { key: 'GroupName', label: '그룹'},
        { key: 'StartDateValue', label: '상담일시'},
        { key: 'KindDetailValue', label: '상담 종류'},
        { key: 'ServiceValue', label: '상담 방식'},
        { key: 'StateValue', label: '방문 여부'},
        { key: 'FeeValue', label: '비용', align: 'right' }, 
        { key: 'IsFeeValue', label: '수납 여부', align: 'center' }
    ];

    const [search, setSearch] = useState('');
    const [sumFee, setSumFee] = useState(0);
    const [filterDate, setFilterDate] = useState([YyyyMmDd(new Date(new Date().setDate(1))),null]);
    const [csvData, setCsvData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [counselorId, setCounselorId] = useState('');

    const handleClickDownload = () => {
        setLoading(true);
        const rowData = filterDatas(props.schedules);
        let csvData = [
            ['No.', '상담자', '상담자ID', '내담자', '내담자ID', '그룹', '상담일시', '상담 종류', '상담 방식', '방문 여부', '비용', '수납 여부']
        ];
      
        for(let i = 0; i < rowData.length; i++){
            csvData.push([
                rowData[i].No, 
                rowData[i].CounselorName, 
                rowData[i].Counselor_id?.ID, 
                rowData[i].ClientName, 
                rowData[i].NickName, 
                rowData[i].GroupName, 
                rowData[i].StartDateValue, 
                rowData[i].KindDetailValue, 
                rowData[i].ServiceValue, 
                rowData[i].StateValue,
                rowData[i].FeeValue, 
                rowData[i].IsFeeValue
            ]);
        }
        setCsvData(csvData);
        setTimeout(() => {
            clickLink();
            setLoading(false);
            enqueueSnackbar('다운로드가 완료되었습니다.', { variant: 'info' });
        }, [2000]);
    };
    const clickLink = () => {
        window.document.getElementById('csv_download').click();
    };

    const handleClickDetail = (data) => {
        // navigate(`/manage/supervisee/${data.ClientNo}`);
        return false;
    };

    const filterDatas = (datas) => {
        let tumpData = [...datas];
        if(filterDate[0]){
            tumpData = tumpData.filter((a) => (new Date(a.StartDate) >= new Date(filterDate[0])));
        }
        if(filterDate[1]){
            tumpData = tumpData.filter((a) => (new Date(a.StartDate) <= new Date(`${filterDate[1]} 23:59:59`)));
        }
        if(counselorId){
            tumpData = tumpData.filter((a) => (a.Counselor_id._id === counselorId));
        }
        if(tumpData){
            return tumpData.filter((item) => {
                let returnData = false;
                for(let i = 0; i < keys.length; i++){
                    if(item[`${keys[i].key}`]?.toString().indexOf(search) > -1){
                        returnData = true;
                        break;                    
                    }
                }
                return returnData;
            });
        }else{
            return [];
        }
    };
    // 필터 후 총액 계산
    useEffect(() => {
        const datas = filterDatas(props.schedules);
        let sum = 0;
        for(let i = 0; i < datas.length; i++){
            if(datas[i].IsFee){
                sum += datas[i].Fee ? Number(datas[i].Fee) : 0;
            }
        }
        setSumFee(sum);
    }, [search, filterDate, props.schedules, counselorId]);

    return (
        <>
        <ListContainer title='내담자 상담 목록'>
            <Grid container justifyContent={'space-between'}>
                <Grid container item sm={12} md={8} lg={8} style={{ marginBottom: '16px', paddingRight: '8px' }} spacing={1}>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <Select
                            value={props.user.Status === 2 ? props.user._id : counselorId}
                            onChange={(e) => setCounselorId(e.target.value)}
                            displayEmpty
                            fullWidth
                            disabled={props.user.Status === 2}
                            size={'small'}
                        >
                            <MenuItem value='' disabled={!(Boolean(counselorId))}><span>{Boolean(counselorId) ? '선택 취소' : '상담자 전체'}</span></MenuItem>
                            {props.members.map((item, i) => (
                            <MenuItem key={i} value={item.value} disabled={Boolean(item.disabled)}>{item.label}</MenuItem> 
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <SearchForm
                            value={search}
                            handleChange={(e) => setSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid item container justifyContent={'space-between'} alignItems={'center'} xs={12} sm={12} md={6} lg={6}>
                        <Grid item xs={5.5}>
                            <OutlinedInput
                                type={'date'}
                                size='small'
                                fullWidth
                                sx={{ mr: 1 }}
                                value={filterDate[0]}
                                onChange={(e) => setFilterDate([e.target.value, filterDate[1]])}
                            />
                        </Grid>
                        <Grid item xs={0.1} sx={{ textAlign: 'center' }}>
                            ~
                        </Grid>
                        <Grid item xs={5.5}>
                            <OutlinedInput
                                type={'date'}
                                size='small'
                                fullWidth
                                value={filterDate[1]}
                                onChange={(e) => setFilterDate([filterDate[0], e.target.value])}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} md={4} lg={4} alignItems={'flex-end'} style={{ marginBottom: '16px' }}>
                    <Grid item xs={12} container justifyContent={'flex-end'} spacing={1}>
                        <Grid item>
                            <SmallSubmitButton label='다운로드' handleClick={handleClickDownload} loading={loading} />
                        </Grid>
                        <Grid item>
                            <SmallSubmitButton  label='PDF' handleClick={() => window.open(`${window.location.origin}/table/${search ? search : 'null'}/${filterDate[0] ? filterDate[0] : 'null'}/${filterDate[1] ? filterDate[1] : 'null'}/${counselorId ? counselorId : 'null'}`)} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <DataTableSort
                    keys={keys}
                    datas={filterDatas(props.schedules)}
                    delete={false}
                    update={false}
                    nonePage={true}
                    search={search}
                    defaultSort={[0, 3]}
                    handleClickDetail={handleClickDetail}
                />
            </Grid>
            <Grid container justifyContent={'flex-end'} alignItems={'center'} sx={{ mt: 1 }}>
                <Grid item xs={9} md={3}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'center'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            합계
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9}
                            style={{
                                paddingLeft: '16px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {sumFee.toLocaleString()}원
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} md={1} >
                    <TooltipButton tooltip={'현재 테이블에 보이는 수납된 비용을 합산한 금액입니다. 담당자, 기간 및 검색 조건을 잘 활용하시기 바랍니다'} size='medium' />
                </Grid>
            </Grid>
        </ListContainer>
        <CSVLink style={{ display: 'none' }} data={csvData} separator=',' id='csv_download' filename={`download_${YyyyMmDdHhMmSs(new Date())}`}>Download me</CSVLink>
        </>
    );
};

export default ClientListView;